import React from 'react'
import './HeroSection.css'

function HeroSection() {
    return(
        <>
        <div className='hero-container'>
            <p>
                Welcome to Sarsen Whatmore Management
            </p>
        </div>
        </>
     
    )
}

export default HeroSection